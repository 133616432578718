import { ClassData } from '@models/class-data';

export class EventAttendeeQuestionOption {
  id?: number;

  eventAttendeeQuestionId?: number;

  label: string;
  order: number;

  constructor(json: ClassData<EventAttendeeQuestionOption>) {
    this.id = json.id;

    this.eventAttendeeQuestionId = json.eventAttendeeQuestionId;

    this.label = json.label;
    this.order = +json.order;
  }
}
