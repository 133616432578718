import { ClassData } from '@models/class-data';
import { EventTemplateAsset } from '@models/events/event-template-asset.model';
import { Helpers } from '@utils/helpers';
import { EventTemplateMarketplaceVisual } from './event-template-marketplace-visual.model';

export class EventTemplateVisual {
  id: number;

  thumbnailUrl: string;
  logoUrl?: string;

  keyVisual1Id?: number;
  keyVisual1?: EventTemplateAsset;

  keyVisual2Url?: string;
  patternUrl?: string;

  backgroundId?: number;
  background?: EventTemplateAsset;
  backgroundColor: string;

  footerColor: string;

  effectId?: number;
  effect?: EventTemplateAsset;

  marketplaceVisuals: EventTemplateMarketplaceVisual[];

  constructor(json: ClassData<EventTemplateVisual>) {
    this.id = +json.id;

    this.thumbnailUrl = json.thumbnailUrl;
    this.logoUrl = json.logoUrl;

    if (json.keyVisual1Id !== undefined) {
      this.keyVisual1Id = +json.keyVisual1Id;
    }
    if (json.keyVisual1) {
      this.keyVisual1 = new EventTemplateAsset(json.keyVisual1);
    }

    this.keyVisual2Url = json.keyVisual2Url;
    this.patternUrl = json.patternUrl;

    if (json.backgroundId !== undefined) {
      this.backgroundId = +json.backgroundId;
    }
    if (json.background) {
      this.background = new EventTemplateAsset(json.background);
    }
    this.backgroundColor = json.backgroundColor;

    this.footerColor = json.footerColor;

    if (json.effectId !== undefined) {
      this.effectId = +json.effectId;
    }
    if (json.effect) {
      this.effect = new EventTemplateAsset(json.effect);
    }

    this.marketplaceVisuals = json.marketplaceVisuals;
  }

  isBackgroundImage(): boolean {
    return (
      !!this.background && Helpers.isImageMimeType(this.background.mimeType)
    );
  }

  isBackgroundVideo(): boolean {
    return (
      !!this.background && Helpers.isVideoMimeType(this.background.mimeType)
    );
  }

  isEffectImage(): boolean {
    return !!this.effect && Helpers.isImageMimeType(this.effect.mimeType);
  }
}
