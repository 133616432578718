import { ClassData } from '@models/class-data';
import { EventWeddingPartyItem } from './event-wedding-party-item.model';

export class EventWeddingParty {
  id: number;

  items?: EventWeddingPartyItem[];

  constructor(json: ClassData<EventWeddingParty>) {
    this.id = +json.id;

    if (json.items) {
      this.items = this.mapItems(json.items);
    }
  }

  private mapItems(items: EventWeddingPartyItem[]): EventWeddingPartyItem[] {
    return items.map((it) => new EventWeddingPartyItem(it));
  }
}
