export enum EventCampaignStatus {
  APPROVED = 'APPROVED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  NOT_APPROVED = 'NOT_APPROVED',
}

export namespace EventCampaignStatus {
  export function getAllEventCampaignStatuses(): EventCampaignStatus[] {
    return [
      EventCampaignStatus.APPROVED,
      EventCampaignStatus.WAITING_FOR_APPROVAL,
      EventCampaignStatus.NOT_APPROVED,
    ];
  }

  export function getDisplayName(status?: EventCampaignStatus): string {
    switch (status) {
      case EventCampaignStatus.APPROVED:
        return 'APP.CAMPAIGNS.EVENT_STATUS.APPROVED';
      case EventCampaignStatus.WAITING_FOR_APPROVAL:
        return 'APP.CAMPAIGNS.EVENT_STATUS.WAITING_FOR_APPROVAL';
      case EventCampaignStatus.NOT_APPROVED:
        return 'APP.CAMPAIGNS.EVENT_STATUS.NOT_APPROVED';
      default:
        return '';
    }
  }
}
