import { ClassData } from '@models/class-data';

export class EventTestimonial {
  id: number;
  userAvatarUrl?: string;
  userName?: string;
  userJobTitle?: string;
  userCity?: string;
  description?: string;
  order: number;

  constructor(json: ClassData<EventTestimonial>) {
    this.id = +json.id;
    this.userAvatarUrl = json.userAvatarUrl;
    this.userName = json.userName;
    this.userJobTitle = json.userJobTitle;
    this.userCity = json.userCity;
    this.description = json.description;
    this.order = json.order;
  }
}
