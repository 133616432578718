import { ClassData } from '@models/class-data';

export class EventTimetableItem {
  id: number;

  eventTimetableId: number;

  timeFrom: string;
  timeTo: string;
  day: Date;

  title: string;
  description: string;
  order: number;

  constructor(json: ClassData<EventTimetableItem>) {
    this.id = +json.id;

    this.eventTimetableId = +json.eventTimetableId;

    this.timeFrom = json.timeFrom;
    this.timeTo = json.timeTo;
    this.day = json.day;

    this.title = json.title;
    this.description = json.description;
    this.order = json.order;
  }
}
