export enum EventCampaignPartyStatus {
  DRAFT = 'DRAFT',
  UNQUALIFIED = 'UNQUALIFIED',
  QUALIFIED_MISSING_DATA = 'QUALIFIED_MISSING_DATA',
  QUALIFIED_COMPLETE = 'QUALIFIED_COMPLETE',
  NO_SUPPORT = 'NO_SUPPORT',
  SUPPORTED = 'SUPPORTED',
}

export namespace EventCampaignPartyStatus {
  export function getAllEventCampaignPartyStatuses(): EventCampaignPartyStatus[] {
    return [
      EventCampaignPartyStatus.DRAFT,
      EventCampaignPartyStatus.UNQUALIFIED,
      EventCampaignPartyStatus.QUALIFIED_MISSING_DATA,
      EventCampaignPartyStatus.QUALIFIED_COMPLETE,
      EventCampaignPartyStatus.NO_SUPPORT,
      EventCampaignPartyStatus.SUPPORTED,
    ];
  }

  export function getDisplayName(status?: EventCampaignPartyStatus): string {
    switch (status) {
      case EventCampaignPartyStatus.DRAFT:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.DRAFT';
      case EventCampaignPartyStatus.UNQUALIFIED:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.UNQUALIFIED';
      case EventCampaignPartyStatus.QUALIFIED_MISSING_DATA:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.QUALIFIED_MISSING_DATA';
      case EventCampaignPartyStatus.QUALIFIED_COMPLETE:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.QUALIFIED_COMPLETE';
      case EventCampaignPartyStatus.NO_SUPPORT:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.NO_SUPPORT';
      case EventCampaignPartyStatus.SUPPORTED:
        return 'APP.CAMPAIGNS.EVENT_PARTY_STATUS.SUPPORTED';
      default:
        return '';
    }
  }
}
