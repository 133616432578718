import { ClassData } from '@models/class-data';
import { EventCategory } from '@models/event-categories/event-category.model';
import { AffiliateProductPlacement } from '@models/affiliate-products/affiliate-product-placement.enum';
import { AffiliateProductPartner } from '@models/affiliate-products/affiliate-product-partner.enum';
import { AffiliateProductStatus } from '@models/affiliate-products/affiliate-product-status.enum';
import { User } from '@models/users/user.model';
import { AffiliateProductType } from './affilaite-product-type.enum';

export class AffiliateProduct {
  id: number;
  createdAt?: Date;

  eventCategories?: EventCategory[];
  eventSubcategories?: EventCategory[];

  owner?: User;

  name: string;
  description: string;
  link: string;
  network: AffiliateProductPartner;

  pictureUrl?: string;
  videoUrl?: string;

  status?: AffiliateProductStatus;

  types?: AffiliateProductType[];

  affiliateProductPlacements?: AffiliateProductPlacement[];
  affiliateProductStats?: any;

  constructor(json: ClassData<AffiliateProduct>) {
    this.id = +json.id;
    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    if (json.eventCategories) {
      this.eventCategories = this.mapEventCategories(json.eventCategories);
    }
    if (json.eventSubcategories) {
      this.eventSubcategories = this.mapEventCategories(
        json.eventSubcategories,
      );
    }
    this.owner = json.owner;

    this.name = json.name;
    this.description = json.description;
    this.link = json.link;
    this.network = json.network;

    this.pictureUrl = json.pictureUrl;
    this.videoUrl = json.videoUrl;

    this.status = json.status;

    this.types = json.types;

    this.affiliateProductPlacements = json.affiliateProductPlacements;
    this.affiliateProductStats = json.affiliateProductStats;
  }

  getAffiliateNetworkDisplayName() {
    return AffiliateProductPartner.getDisplayName(this.network);
  }

  getEventCategoriesIds(): number[] | undefined {
    return this.eventCategories?.map((category) => category.id);
  }

  getEventSubcategoriesIds(): number[] | undefined {
    return this.eventSubcategories?.map((category) => category.id);
  }

  getEventCategoryNames(): string {
    if (!this.eventCategories) {
      return '';
    }
    return this.eventCategories.map((category) => category.name).join(', ');
  }

  getEventSubcategoryNames(): string {
    if (!this.eventSubcategories) {
      return '';
    }
    return this.eventSubcategories.map((category) => category.name).join(', ');
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }
}
