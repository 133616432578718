import { ClassData } from '@models/class-data';

export class EventFaq {
  id: number;

  eventId?: number;
  eventTemplateId?: number;

  question: string;
  answer: string;

  order: number;

  constructor(json: ClassData<EventFaq>) {
    this.id = +json.id;

    if (json.eventId) {
      this.eventId = +json.eventId;
    }
    if (json.eventTemplateId) {
      this.eventTemplateId = +json.eventTemplateId;
    }

    this.question = json.question;
    this.answer = json.answer;

    this.order = +json.order;
  }
}
