export enum EventCampaignWorkingStatus {
  DECORATION_ORDERED = 'DECORATION_ORDERED',
  POSTER_ORDERED = 'POSTER_ORDERED',
  DECORATION_AND_POSTER_ORDERED = 'DECORATION_AND_POSTER_ORDERED',
  PARTICIPATION_PACKAGE_SHIPPED = 'PARTICIPATION_PACKAGE_SHIPPED',
}

export namespace EventCampaignWorkingStatus {
  export function getAllEventCampaignWorkingStatuses(): EventCampaignWorkingStatus[] {
    return [
      EventCampaignWorkingStatus.DECORATION_ORDERED,
      EventCampaignWorkingStatus.POSTER_ORDERED,
      EventCampaignWorkingStatus.DECORATION_AND_POSTER_ORDERED,
      EventCampaignWorkingStatus.PARTICIPATION_PACKAGE_SHIPPED,
    ];
  }

  export function getDisplayName(status?: EventCampaignWorkingStatus): string {
    switch (status) {
      case EventCampaignWorkingStatus.DECORATION_ORDERED:
        return 'APP.CAMPAIGNS.EVENT_WORKING_STATUS.DECORATION_ORDERED';
      case EventCampaignWorkingStatus.POSTER_ORDERED:
        return 'APP.CAMPAIGNS.EVENT_WORKING_STATUS.POSTER_ORDERED';
      case EventCampaignWorkingStatus.DECORATION_AND_POSTER_ORDERED:
        return 'APP.CAMPAIGNS.EVENT_WORKING_STATUS.DECORATION_AND_POSTER_ORDERED';
      case EventCampaignWorkingStatus.PARTICIPATION_PACKAGE_SHIPPED:
        return 'APP.CAMPAIGNS.EVENT_WORKING_STATUS.PARTICIPATION_PACKAGE_SHIPPED';
      default:
        return '';
    }
  }
}
