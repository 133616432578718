import { ClassData } from '@models/class-data';
import { EventTemplateAssetType } from './event-template-asset-type.enum';
import { EventCategory } from '@models/event-categories/event-category.model';

export class EventTemplateAsset {
  id: number;

  createdAt?: Date;

  name: string;
  type: EventTemplateAssetType;

  thumbnailUrl: string;

  url: string;
  mobileUrl: string;

  // H.265 codec used for alpha channel for Safari
  h265Url: string;
  h265MobileUrl: string;

  mimeType: string;

  repeat?: boolean;

  // Fallback background color and color of text
  backgroundColor?: string;
  color?: string;

  categories?: EventCategory[];

  keywordIds?: number[];

  constructor(json: ClassData<EventTemplateAsset>) {
    this.id = +json.id;

    if (json.createdAt) {
      this.createdAt = new Date(json.createdAt);
    }

    this.name = json.name;
    this.type = json.type;
    this.thumbnailUrl = json.thumbnailUrl;
    this.url = json.url;
    this.mobileUrl = json.mobileUrl;

    this.h265Url = json.h265Url;
    this.h265MobileUrl = json.h265MobileUrl;

    this.mimeType = json.mimeType;

    this.repeat = !!json.repeat;

    this.backgroundColor = json.backgroundColor;
    this.color = json.color;

    if (json.categories) {
      this.categories = this.mapEventCategories(json.categories);
    }

    if (json.keywordIds) {
      this.keywordIds = json.keywordIds;
    }
  }

  private mapEventCategories(categories: EventCategory[]): EventCategory[] {
    return categories.map((it) => new EventCategory(it));
  }

  getEventCategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    const mainCategories = this.categories
      .filter((category) => !category.parentCategoryId)
      .map((category) => category.id);

    return [...new Set(mainCategories)];
  }

  getEventSubcategoriesIds(): number[] | undefined {
    if (!this.categories) {
      return undefined;
    }

    return this.categories
      .filter(
        (category) =>
          category.parentCategoryId && category.parentCategoryId > 0,
      )
      .map((category) => category.id);
  }

  getEventSubcategoriesNames(): string {
    if (!this.categories) {
      return '';
    }

    const subCategories = this.categories.filter(
      (category) => category.parentCategoryId && category.parentCategoryId > 0,
    );

    if (subCategories.length === 0) {
      return '';
    }

    return subCategories.map((category) => category.name).join(', ');
  }
}
