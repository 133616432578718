import { ClassData } from '@models/class-data';

export class EventSponsor {
  id: number;
  name?: string;
  imageUrl?: string;
  linkUrl?: string;
  order: number;

  constructor(json: ClassData<EventSponsor>) {
    this.id = +json.id;
    this.name = json.name;
    this.imageUrl = json.imageUrl;
    this.linkUrl = json.linkUrl;
    this.order = json.order;
  }
}
