import { ClassData } from '@models/class-data';
import { EventTimetableItem } from './event-timetable-item.model';

export class EventTimetable {
  id: number;

  items?: EventTimetableItem[];

  constructor(json: ClassData<EventTimetable>) {
    this.id = +json.id;

    if (json.items) {
      this.items = this.mapItems(json.items);
    }
  }

  private mapItems(items: EventTimetableItem[]): EventTimetableItem[] {
    return items.map((it) => new EventTimetableItem(it));
  }
}
