import { ClassData } from '@models/class-data';
import { EventWeddingPartyItemType } from './event-wedding-party-item-type.enum';

export class EventWeddingPartyItem {
  id: number;

  eventWeddingPartyId: number;

  type: EventWeddingPartyItemType;

  description: string;

  name: string;
  pictureUrl: string;

  constructor(json: ClassData<EventWeddingPartyItem>) {
    this.id = +json.id;

    this.eventWeddingPartyId = +json.eventWeddingPartyId;

    this.type = json.type;

    this.description = json.description;

    this.name = json.name;
    this.pictureUrl = json.pictureUrl;
  }
}
