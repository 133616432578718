export enum EventTemplateAccess {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  DRAFT = 'DRAFT',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export namespace EventTemplateAccess {
  export function getAllEventTemplateAccesses(): EventTemplateAccess[] {
    return [
      EventTemplateAccess.PUBLIC,
      EventTemplateAccess.PRIVATE,
      EventTemplateAccess.DRAFT,
      EventTemplateAccess.INACTIVE,
      EventTemplateAccess.ARCHIVED,
    ];
  }

  export function getCreationEventTemplateAccesses(): EventTemplateAccess[] {
    return [
      EventTemplateAccess.PUBLIC,
      EventTemplateAccess.PRIVATE,
      EventTemplateAccess.DRAFT,
    ];
  }

  export function getDisplayName(access?: EventTemplateAccess): string {
    switch (access) {
      case EventTemplateAccess.PUBLIC:
        return 'APP.PUBLIC';
      case EventTemplateAccess.PRIVATE:
        return 'APP.PRIVATE';
      case EventTemplateAccess.DRAFT:
        return 'APP.DRAFT';
      case EventTemplateAccess.INACTIVE:
        return 'APP.INACTIVE';
      case EventTemplateAccess.ARCHIVED:
        return 'APP.ARCHIVED';
      default:
        return '';
    }
  }
}
