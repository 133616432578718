import { ClassData } from '@models/class-data';
import { Keyword } from '@models/shared/keyword.model';
import { EventTemplateSeoTranslation } from '@models/design-templates/event-template-seo-translation.model';

export class EventTemplateSeo {
  id: number;

  sharingPictureUrl: string;
  seoPictureTitle?: string;
  seoPictureAlt?: string;
  title: string;
  description: string;
  pageTitle?: string;
  pageDetailSummary?: string;
  pageDetail?: string;

  eventCategoryId?: number;
  keywords?: Keyword[];

  translations?: EventTemplateSeoTranslation[];

  constructor(json: ClassData<EventTemplateSeo>) {
    this.id = +json.id;

    this.sharingPictureUrl = json.sharingPictureUrl;
    if (json.seoPictureTitle) {
      this.seoPictureTitle = json.seoPictureTitle;
    }

    if (json.seoPictureAlt) {
      this.seoPictureAlt = json.seoPictureAlt;
    }

    this.title = json.title;
    this.description = json.description;
    this.pageTitle = json.pageTitle;
    this.pageDetailSummary = json.pageDetailSummary;
    this.pageDetail = json.pageDetail;

    if (json.eventCategoryId) {
      this.eventCategoryId = +json.eventCategoryId;
    }
    if (json.keywords) {
      this.keywords = json.keywords;
    }

    if (json.translations !== undefined) {
      this.translations = json.translations;
    }
  }
}
