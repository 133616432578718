import { AffiliateProduct } from '@models/affiliate-products/affiliate-product.model';
import { ClassData } from '@models/class-data';
import { EventAffiliateProductType } from '@models/events/event-affiliate-product-type.enum';

export class EventTemplateAffiliateProduct {
  id?: number;

  affiliateProductId: number;
  affiliateProduct?: AffiliateProduct;
  type: EventAffiliateProductType;

  constructor(json: ClassData<EventTemplateAffiliateProduct>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.affiliateProductId = +json.affiliateProductId;
    if (json.affiliateProduct) {
      this.affiliateProduct = new AffiliateProduct(json.affiliateProduct);
    }
    this.type = json.type;
  }
}
