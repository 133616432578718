import { ClassData } from '@models/class-data';

export class EventTemplateMarketplaceVisual {
  id?: number;

  url: string;
  title: string;
  alt: string;

  constructor(json: ClassData<EventTemplateMarketplaceVisual>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.url = json.url;
    this.title = json.title;
    this.alt = json.alt;
  }
}
